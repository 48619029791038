@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* option {
  background-color: #D7D7DE;
  color: black;
  font-family: Roboto;
  padding: 1vh 1vw;
  text-align: center;
} */

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  --font-1: 'Arial';
  --font-2: 'Roboto';
  --font-3: "Onest", sans-serif;
  --background-primary-1: #3E6975;
  --background-secondary-1: #F3AF8E;
  --background-1: #061e33;
  --background-2: #647280;
  --background-3: #bfc5ca;
  --background-4: #ffffff;
  --background-5: #1a2d3ea9;
  --background-6: #51606d;
  --background-7: #2a3c4c;
  --background-8: #6e82a0;
  --background-9: #1a2d3e;
  --background-10: #d7d7de;
  --background-11: #be6bcc;
  --background-12: #2a3c4c;
  --background-13: #03dac5;
  --background-14: #ffffffcc;
  --background-15: #3de2d2cc;
  --background-16: #3de2d2;
  --background-17: #f6f7facc;
  --background-18: #f7b7afb3;
  --background-19: #3de2d2b3;
  --background-20: #4DBFDB;
  --background-21: #E9A5E1;
  --text-primary-1: #B7E5E4;
  --text-secondary-1: #3E6975;
  --text-1: #637381;
  --text-2: #bfc5ca;
  --text-3: #ffffff;
  --text-4: #cdd1d5;
  --text-5: #1a3043;
  --text-6: #020202d9;
  --text-7: #ffffffcc;
  --text-8: #ffffff66;
  --text-9: #ffffffd9;
  --text-10: #ffffff80;
  --text-11: #fdf8f880;
  --text-12: #060607cc;
  --text-13: #fdf8f8cc;
  --text-14: #16161c;
  --text-15: #F2F6F7;
  --accent-1: #13c3b2;
  --accent-2: #055b62;
  --accent-3: #0bc4f0;
  --accent-4: #3baca1;
  --accent-5: #ff43a4;
  --button-1: #157fcc;
  --button-2: #65caaf;
  --button-3: #f7b7af;
  --button-4: #f18172;
  --border-1: #f6f7fa26;
  --height-1: 650px;
  --height-2: 572px;
  --turquoise-1: #4DBFDB;
}

main {
  flex-grow: 1;
}

.fc-view {
  background-color: #ffffff;
}

.tippy-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d7d7de 0% 0% no-repeat padding-box;
  border: 1px solid #f6f7fa;
  opacity: 1;
  border-radius: 0%;
  width: 135px;
  height: 77px;
  text-align: center;
  font: normal normal normal 10px/15px Roboto;
  letter-spacing: 0px;
  color: #16161c;
}

.dot-blue {
  height: 12px;
  width: 12px;
  background-color: #359eeb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}
.dot-white {
  height: 12px;
  width: 12px;
  background-color: #f6f7fa;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}
.dot-beach {
  height: 12px;
  width: 12px;
  background-color: #3de2d2;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}
.form-holder {
  background: linear-gradient(116.1deg, rgba(154, 202, 205, 0.2) 2.2%, rgba(154, 202, 205, 0.138) 98.12%);
  border-radius: 16px;
  position: relative;
  padding: 16px;
  z-index: 10;
}

.form-holder::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(145deg, rgba(154, 202, 205, 1), rgba(154, 202, 205, 0.2));
  border-radius: 16px; 
  z-index: -5;
  opacity: 1;
}

.form-holder::after {
  content: '';
  position: absolute;
  top: 0px; 
  left: 0px;
  right: 0px;
  bottom: 0px; 
  background: var(--background-primary-1); 
  border-radius: 16px; 
  z-index: -4;
  opacity: 0.8;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: calc(100% - 0.5rem) center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%233E6975" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
}
